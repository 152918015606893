import { CheckoutSelectionCardModel, CheckoutSelectionCardKenticoModel } from "@models/Checkout/CheckoutSelectionCard"

export const mapToCheckoutSelectionCard = (data: CheckoutSelectionCardKenticoModel): CheckoutSelectionCardModel => {
    return {
        body: data.body?.value,
        heading: data.heading.value,
        content: data.content?.value,
        items: data.items?.linked_items,
        icon: data.icon?.value
    }
}