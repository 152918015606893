export type CheckoutGiftingCardModel = {
    message: string
    declaration: string
    footer1: string
    footer2: string
    footer3: string
}

export type CheckoutGiftingKenticoModel = {
    message: {
        value: string
    }
    declaration: {
        value: string
    }
    footer1: {
        value: string
    }
    footer2: {
        value: string
    }
    footer3: {
        value: string
    }
}

export const mapToCheckoutGiftingTemplate = (data: CheckoutGiftingKenticoModel): CheckoutGiftingCardModel => {
    return {
        message: data.message?.value,
        declaration: data.declaration?.value,
        footer1: data.footer1?.value,
        footer2: data.footer2?.value,
        footer3: data.footer3?.value
    }
}