import React from 'react'
import {
    CheckoutSelectionCardContainer,
    CheckoutSelectionCardIndicator,
    CheckoutSelectionCardHeading,
    CheckoutSelectionCardButton,
    CheckoutIconImage,
    SelectionSubTitleWrapper,
    SelectionSubTitleText,
    PlatemanufactureSelectionCardButton

} from './CheckoutSelectionCard.styles'
import { Paragraph2 } from '@styles/Global.styles'
import theme from '@styles/theme'
import { Icon, Divider, Label, Grid } from 'semantic-ui-react'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { SelectedItem } from '@components/Mobile/PlateManufactureSelection/PlateManufactureSelection.styles'

const checkoutIcon = require('@assets/images/checkout.png')
const expresscheckoutIcon = require('@assets/images/express-checkout.png')
export type CheckoutSelectionCardProps = {
    card: CheckoutSelectionCardModel
    onSelect: Function
    isSelected: boolean
    manufactureSelection?: boolean
    expressCheckout?: boolean
    hideButton?: boolean
}

class CheckoutSelectionCard extends React.Component<CheckoutSelectionCardProps>{
    render() {

        const { heading, body, content, items, icon } = this.props.card;
        const { isSelected, manufactureSelection, expressCheckout, hideButton = false } = this.props;

        let isSelectedClass = isSelected;
        if(manufactureSelection && manufactureSelection == true){
            isSelectedClass = false
        }

        return (
            <CheckoutSelectionCardContainer className={isSelectedClass ? 'selected' : isSelected ? 'selectedMan' : ''} onClick={() => hideButton && this.props.onSelect()}>
                <div>
                    <CheckoutSelectionCardHeading>{heading}</CheckoutSelectionCardHeading>
                    {body && <span style={{color: theme.brand.colors.darkGrey}}>{body}</span>}
                    {/* {content && <p>{content}</p>} */}
                    {content && <span style={{color: theme.brand.colors.darkGrey}} dangerouslySetInnerHTML={{ __html: content }} />}
                    
                    {
                        manufactureSelection && manufactureSelection == true &&
                        isSelected &&
                        <>
                            <Divider />
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                {items.map((item) => {
                                   return <>
                                            <SelectedItem>
                                                <Icon name='check circle outline' size='big'/>
                                                <span style={{paddingLeft:'10px'}} dangerouslySetInnerHTML={{ __html: item.elements.title.value.replace('<em>', '<span style="color:#707070">').replace('em', 'span') }}></span>
                                            </SelectedItem>
                                            <br/>
                                          </>
                                })}
                                </Grid.Column>
                            <Divider />
                            <span style={{fontSize: "11px;"}}>*Vehicle cannot be on exemption</span>
                        </>
                    }
                    { !manufactureSelection && manufactureSelection == false &&<Paragraph2 color='#707070' dangerouslySetInnerHTML={{ __html: content }} />}
                </div>
                <div>
                    { manufactureSelection && manufactureSelection == true && <PlatemanufactureSelectionCardButton onClick={() => this.props.onSelect(isSelected)} id="csc-select" buttonType='secondary' color={theme.mobileTheme.buttonBgColor} textColor={theme.mobileTheme.buttonColor}>{isSelected ? '- REMOVE' : '+ ADD'}</PlatemanufactureSelectionCardButton>}
                </div>
                {!hideButton &&
                <div>
                    {
                        isSelected &&
                        <CheckoutSelectionCardButton id="csc-selected" buttonType='primary' color={theme.brand.colors.green}>SELECTED</CheckoutSelectionCardButton>
                    }
                    {
                        !isSelected &&
                        <CheckoutSelectionCardButton id="csc-select" buttonType='secondary' color={theme.brand.colors.black} onClick={this.props.onSelect}>SELECT</CheckoutSelectionCardButton>
                    }
                </div>}
                {
                    isSelected &&
                    <CheckoutSelectionCardIndicator>
                        <Icon name='check' size='small' />
                    </CheckoutSelectionCardIndicator>
                }
            </CheckoutSelectionCardContainer>
        );
    }
}



export default CheckoutSelectionCard